import { Link } from "gatsby";
import React from "react";
import Image, { ImageType } from "../components/Image";
import SEO from "../components/Seo";
import "./index.css";

const pathMappings: Record<string, string> = {
  welcome: "",
};

/**
 * Returns the requested path from the provided url hash
 * The path is stripped of a trailing / if one exists.
 * @param hash The hash of the current url
 */
const getRequestedPath = (hash: string) => {
  const path = hash.startsWith("#") ? hash.slice(1) : hash;
  return path && path.startsWith("/") ? path.slice(1) : path;
};

const redirectOldUrls = () => {
  if (window.location.hash) {
    const requestedPath = getRequestedPath(window.location.hash);
    const newPath = pathMappings.hasOwnProperty(requestedPath)
      ? pathMappings[requestedPath]
      : requestedPath;

    const redirectUrl = `${window.location.origin}/${newPath}`;

    window.location.href = redirectUrl;
  }
};

const IndexPage = () => {
  React.useEffect(redirectOldUrls, []);

  return (
    <>
      <SEO title="Home" />
      <div className="flex flex-col items-center w-full p-2 justify-items-center">
        <Image
          image={ImageType.headshot}
          className={`mb-8 w-full rounded-lg max-w-xxs`}
        />
        <div className="prose">
          <Link to="/about">Enter</Link>
        </div>
      </div>
    </>
  );
};

export default IndexPage;
